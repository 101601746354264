<template>
  <Rate v-model="value" @change="emitValue"></Rate>
</template>

<script>
import { Rate } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

export default {
  data() {
    return {
      value: null,
    };
  },
  components: {
    Rate,
  },
  methods: {
    emitValue(newValue) {
      this.$emit("update:value", newValue);
    },
  },
};
</script>
